import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

export default function TooltipButton({title, icon, onClick, size, className, disabled}) {
    return (
        <Tooltip title={title}>
            <IconButton size={size ? size : "small"}
                        className={className}
                        onClick={onClick}
                        disabled={disabled}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}

TooltipButton.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    classes: PropTypes.object,
}