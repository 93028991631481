import api from "../../../config/axiosConfig";
import {toast} from "react-toastify";

export function changePassword(admin) {
    api.post(`/admin/change-password`, admin)
        .then(() => toast.success(`Password changed successfully!`))
        .catch(() => toast.error(`There was an error changing the password!`))
}

const adminApi = { changePassword }

export default adminApi