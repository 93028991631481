import axios from 'axios'
import {BASE_URL} from "../utils/constants";

const api = axios.create({
    baseURL: BASE_URL
})

api.defaults.headers.common['Accept'] = 'application/json'
api.defaults.headers.common['Content-Type'] = 'application/json'


export default api