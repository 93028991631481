import {useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import React, {useEffect} from "react";
import {LOGIN_PAGE} from "../../utils/routingPaths";

export default function PrivateRoute(props) {
    const isAuthenticated = useSelector(state => state.app.isAuthenticated)

    return (
        <>
            {isAuthenticated
                ? <Route {...props} />
                : <Redirect to={LOGIN_PAGE} />}
        </>
    )
}