import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import axios from "axios";
import {BASE_URL} from "../utils/constants";

const initialState = {
    activeTab: 0,
    isDark: true,
    isAuthenticated: false,
    email: '',
    token: null,

    isLoginLoading: false
}

export const authenticateAdmin = createAsyncThunk(
    'app/authenticateAdmin',
    async (admin) => {
        const response = await axios.post(`${BASE_URL}/admin/authenticate`, admin)
        return response.data
    }
)

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeTab(state, action) {
            state.activeTab = action.payload
        },
        changeTheme(state, action) {
            state.isDark = action.payload
        },
        changeAuthentication(state, action) {
            state.isAuthenticated = action.payload
        },
        resetLoading(state, action) {
            state.isLoginLoading = false
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(authenticateAdmin.fulfilled, (state, action) => {
                state.email = action.payload.email
                state.token = action.payload.token
                state.isAuthenticated = true
                state.isLoginLoading = false
                toast.success("Authenticated successfully!")
            })
            .addCase(authenticateAdmin.rejected, (state, action) => {
                state.isLoginLoading = false
                toast.error("There was an error: Authentication failed!")
            })
            .addCase(authenticateAdmin.pending, (state, action) => {
                state.isLoginLoading = true
            })
    })
})

export const { changeTab, changeTheme, changeAuthentication, resetLoading } = appSlice.actions
export default appSlice.reducer