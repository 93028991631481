import React, {useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import {authenticateAdmin, resetLoading} from "../../state/appSlice";
import {Redirect} from "react-router-dom";
import {USER_MANAGEMENT} from "../../utils/routingPaths";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoginPage() {
    const classes = useStyles()
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(state => state.app.isAuthenticated)
    const email = useSelector(state => state.app.email)
    const isLoginLoading = useSelector(state => state.app.isLoginLoading)

    const [state, setState] = React.useState({username: null, password: null})

    function handleLogin() {
        const admin = { email: state.username, password: state.password }
        dispatch(authenticateAdmin(admin))
    }

    const handleChange = (event) => {
        setState(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    };

    useEffect(() => {
        dispatch(resetLoading())
    }, [])

    const textInput = (id, label, value, type) =>
        <TextField fullWidth variant={"outlined"} className={classes.input}
                   id={id}
                   onChange={handleChange}
                   label={label}
                   value={value}
                   type={type}
        />

    if (isAuthenticated) {
        return <Redirect to={USER_MANAGEMENT}/>
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
        >
            {!isAuthenticated ?
            <Card variant={"outlined"} className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography color="textSecondary" gutterBottom>
                        You are not logged in!
                    </Typography>
                </Grid>

                {textInput("username", "Username", state.username)}
                {textInput("password", "Password", state.password, "password")}

                <Button variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{height: "40px"}}
                        disabled={isLoginLoading}
                        onClick={handleLogin}
                >
                    {isLoginLoading ? <CircularProgress size={20}/> : "Login"}
                </Button>
            </Card> :
                <Typography color="textSecondary" variant="h3" gutterBottom>
                    {`Welcome, ${email}`}
                </Typography>}


        </Grid>
    )
}

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            width: 400,
        },
        grid: {
            marginTop: 100
        },
        input: {
            marginBottom: theme.spacing(2)
        }
    }),
);