import createTheme from "@material-ui/core/styles/createTheme";

export const theme = (isDark) => createTheme({
    palette: {
        type: isDark ? 'dark' : 'light',
        primary: {
            main: '#00bcd4',
            dark: '#008ba3',
            light: '#62efff',
        },
        secondary: {
            main: '#9c27b0',
        },
    },
})
