import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LoginPage from "../pages/login/LoginPage";
import {
    BASE_PATH,
    LOGIN_PAGE, SETTINGS,
    USER_MANAGEMENT
} from "../utils/routingPaths";
import PrivateRoute from "../features/navigation/PrivateRoute";
import UserManagementPage from "../pages/user-management/UserManagementPage";
import SettingsPage from "../pages/settings/SettingsPage";

export default function AppRoute() {
    return (
        <Switch>
            <PrivateRoute exact path={BASE_PATH} component={UserManagementPage}/>
            <PrivateRoute exact path={USER_MANAGEMENT} component={UserManagementPage}/>
            <PrivateRoute exact path={SETTINGS} component={SettingsPage}/>
            <Route exact path={LOGIN_PAGE} component={LoginPage}/>
        </Switch>
    )
}