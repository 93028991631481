import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Brightness3Icon from "@material-ui/icons/Brightness3";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useSelector, useDispatch} from "react-redux";
import {changeAuthentication, changeTab, changeTheme} from "../../state/appSlice";
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';

import {StyledTab} from "./StyledTab";
import {
    SETTINGS,
    USER_MANAGEMENT
} from "../../utils/routingPaths";

export default function NavTabs() {
    const classes = useStyles();
    const value = useSelector(state => state.app.activeTab)
    const isDark = useSelector(state => state.app.isDark)

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        dispatch(changeTab(newValue))
    };

    const handleThemeClick = () => {
        dispatch(changeTheme(!isDark))
    }

    const handleLogout = () => {
        dispatch(changeAuthentication(false))
    }

    return (
        <AppBar position="static">
            <Box display="flex" className={classes.rootBox}>
                <Tabs
                    value={value}
                    variant="standard"
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <StyledTab label="Users" to={USER_MANAGEMENT} icon={<PeopleIcon />}/>
                    <StyledTab label="Settings" to={SETTINGS} icon={<SettingsIcon />}/>
                </Tabs>

                <Box flexGrow={1} />

                <IconButton onClick={handleThemeClick}>
                    {isDark ? <Brightness7Icon/> : <Brightness3Icon/>}
                </IconButton>

                <IconButton onClick={handleLogout}>
                    <ExitToAppIcon />
                </IconButton>
            </Box>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    rootBox: {
        backgroundColor: theme.palette.background.paper,
    }
}));
