import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import SimpleSelect from "./SimpleSelect";
import MultipleSelect from "./MultipleSelect";

export default function FilterButton({filters, fields, changeFilter, resetFilter}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        let obj = event.target.id
            ? {...filters, [event.target.id]: event.target.value}
            : {...filters, [event.target.name]: event.target.value}
        dispatch(changeFilter(obj))
    };

    const handleReset = () => {
        dispatch(resetFilter())
    }

    const open = Boolean(anchorEl);
    const id = open ? 'filter-button-popover' : undefined;

    const element = (field) => {
        switch (field.type) {
            case 'textField': return <TextField id={field.id} label={field.label} fullWidth value={field.value} onChange={handleChange}/>
            case 'simpleSelect': return <SimpleSelect id={field.id} title={field.label} options={field.options} value={field.value} handleChange={handleChange}/>
            case 'multipleSelect': return <MultipleSelect id={field.id} value={field.value} title={field.label} options={field.options} handleChange={handleChange}/>
            default: return <></>
        }
    }

    return (
        <div>
            <IconButton aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                <FilterListIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                    <Grid container>
                        <Typography className={classes.typography}>Filters</Typography>
                        <Button color="primary" onClick={handleReset}>Reset</Button>
                    </Grid>

                    <Grid container>
                        {fields.map(field => (
                            <Grid item xs={6} className={classes.item}>
                                {element(field)}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Popover>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '800px',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default
    },
    item: {
        padding: theme.spacing(1)
    },
    typography: {
        margin: theme.spacing(1),
    }
}));
