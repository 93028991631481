import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUsers} from "./state/usersSlice";
import UsersTable from "./usersTable";
import LoadingBar from "../../features/common/LoadingBar";

export default function UserManagementPage() {
    const dispatch = useDispatch()

    const users = useSelector(state => state.users.users)
    const filters = useSelector(state => state.users.filters)
    const isLoading = useSelector(state => state.users.isLoading)

    const rows = users ? users.filter(row =>
        (filters.email === '' || row.email.includes(filters.email)) &&
        (filters.imei === '' || row.imei.includes(filters.imei)) &&
        (filters.status.length === 0 || filters.status.includes(row.status))
    ) : []

    useEffect(() => {
        dispatch(fetchUsers())
    }, [])

    return (
        <>
            {isLoading && <LoadingBar />}
            {rows &&
            <UsersTable rows={rows}/>
            }
        </>
    )
}