import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import React from "react";
import {Link} from "react-router-dom";

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        maxWidth: '5%',
        width: '5%',
        padding: '5px',
        minWidth: '100px',
        '&:hover': {
            opacity: 1,
        },
    },
    labelIcon: {
        minHeight: '64px'
    }
}))((props) => <Tab component={Link} {...props} />);