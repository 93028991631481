import React from 'react';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import adminApi from "./state/adminAPI";

export default function SettingsPage() {
    const classes = useStyles()

    const [state, setState] = React.useState({newPassword: '', confirmPassword: ''})
    const email = useSelector(state => state.app.email)

    const handleChange = (event) => {
        setState(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    };

    const textInput = (id, label, value, type) =>
        <TextField fullWidth variant={"outlined"} className={classes.input}
                   id={id}
                   onChange={handleChange}
                   label={label}
                   value={value}
                   type={type}
        />

    const handleChangePassword = () => {
        let admin = {email, password: state.confirmPassword}
        adminApi.changePassword(admin)
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
        >
            <Card variant={"outlined"} className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography color="textSecondary" gutterBottom>
                        Change password
                    </Typography>
                </Grid>

                {textInput("newPassword", "New password", state.newPassword, "password")}
                {textInput("confirmPassword", "Confirm password", state.confirmPassword, "password")}

                <Button variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disabled={state.newPassword !== state.confirmPassword || state.newPassword.length === 0}
                        onClick={handleChangePassword}
                >
                    Change
                </Button>
            </Card>
        </Grid>
    )
}

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            width: 400,
        },
        grid: {
            marginTop: 100
        },
        input: {
            marginBottom: theme.spacing(2)
        }
    }),
);