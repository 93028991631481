import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import usersApi from "./usersAPI";
import {toast} from "react-toastify";

const initialState = {
    users: null,

    filters: {email: '', imei: '', status: ''},

    isLoading: false
}

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async () => {
        const response = await usersApi.getAll()
        return response.data
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        changeFilter: (state, action) => {
            state.filters = action.payload
        },
        removeFilter: (state, action) => {
            state.filters = {...state.filters, [action.payload]: initialState.filters[action.payload]}
        },
        resetFilter: (state, action) => {
            state.filters = initialState.filters
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.users = action.payload
                state.isLoading = false
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.isLoading = false
                toast.error("Error fetching users.")
            })
            .addCase(fetchUsers.pending, (state, action) => {
                state.isLoading = true
            })
    })
})

export const { changeFilter, resetFilter, removeFilter } = usersSlice.actions
export default usersSlice.reducer