import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {fetchUsers} from "./state/usersSlice";
import Paper from "@material-ui/core/Paper";
import {EnhancedTableToolbar} from "./EnhancedTableToolbar";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TooltipButton from "../../features/table/TooltipButton";
import {Done, Clear, Delete} from "@material-ui/icons";
import TablePagination from "@material-ui/core/TablePagination";
import {makeStyles} from "@material-ui/core/styles";
import usersApi from "./state/usersAPI";
import {ALLOWED, DENIED, PENDING} from "./state/userStatus";
import ConfirmationDialog from "../../features/common/ConfirmationDialog";

export default function UsersTable({rows}) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [selectedEmail, setSelectedEmail] = React.useState()
    const [openDialog, setOpenDialog] = React.useState(false)

    const handleOpenConfirmationDialog = (email) => {
        setSelectedEmail(email)
        setOpenDialog(true)
    }
    const handleCloseConfirmationDialog = () => setOpenDialog(false)

    const filters = useSelector(state => state.users.filters)
    const emptyRows = rows ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage) : rowsPerPage

    const loadData = () => dispatch(fetchUsers())

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onAllow = (email) => {
        usersApi.updateStatusByEmail(email, ALLOWED)
            .then(() => loadData())
    }

    const onDeny = (email) => {
        usersApi.updateStatusByEmail(email, DENIED)
            .then(() => loadData())
    }

    const onDelete = (email) => {
        usersApi.deleteUser(email)
            .then(() => loadData())
    }

    const statusColor = (status) => {
        switch (status) {
            case ALLOWED: return classes.success
            case DENIED: return classes.error
            case PENDING: return classes.warning
            default: return undefined
        }
    }

    useEffect(() => {
        setPage(0)
    }, [filters])

    return (
        <>
            {rows &&
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Email</b></TableCell>
                                    <TableCell><b>Imei</b></TableCell>
                                    <TableCell><b>Status</b></TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow
                                            key={row.email}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.imei}</TableCell>
                                            <TableCell className={statusColor(row.status)}><i>{row.status}</i></TableCell>

                                            <TableCell padding={"checkbox"}>
                                                <TooltipButton title={'Allow'} icon={<Done/>} onClick={() => onAllow(row.email)} className={classes.success} disabled={row.status === ALLOWED}/>
                                            </TableCell>
                                            <TableCell padding={"checkbox"}>
                                                <TooltipButton title={'Deny'} icon={<Clear/>} onClick={() => onDeny(row.email)} className={classes.error} disabled={row.status === DENIED}/>
                                            </TableCell>
                                            <TableCell padding={"checkbox"}>
                                                <TooltipButton title={'Delete'} icon={<Delete/>} onClick={() => handleOpenConfirmationDialog(row.email)} className={classes.error}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{height: 53 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows ? rows.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    {openDialog && <ConfirmationDialog title={`Delete user`}
                                                       description={`Are you sure you want to delete ${selectedEmail}? The action cannot be undone!`}
                                                       handleConfirm={() => onDelete(selectedEmail)}
                                                       handleClose={handleCloseConfirmationDialog} />}
                </Paper>
            </div>
            }
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(2)
    },
    table: {
        minWidth: 450,
    },
    error: {
        color: theme.palette.error.main,
    },
    success: {
        color: theme.palette.success.main
    },
    warning: {
        color: theme.palette.warning.main
    }
}));