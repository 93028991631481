import api from "../../../config/axiosConfig";
import {toast} from "react-toastify";

export function getAll() {
    return api.get(`/api/users`)
}

export function updateStatusByEmail(email, status) {
    return api.put(`/api/users?email=${email}&status=${status}`)
        .then(() => toast.success(`Status successfully changed to ${status}`))
        .catch(() => toast.error(`There was an error changing status to ${status}!`))
}

export function deleteUser(email) {
    return api.delete(`/api/users?email=${email}`)
        .then(() => toast.success(`User deleted successfully!`))
        .catch(() => toast.error(`There was an error deleting the user!`))
}

const usersApi = {
    getAll,
    updateStatusByEmail,
    deleteUser
}

export default usersApi