import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        minWidth: "100px"
    }
}));

export default function MultipleSelect({id, title, options, value, handleChange}) {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">{title}</InputLabel>
                <Select
                    labelId="demo-mutiple-name-label"
                    id={id}
                    name={id}
                    multiple
                    value={value}
                    onChange={handleChange}
                    input={<Input />}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>
    );
}
