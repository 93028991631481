import React, {useEffect} from 'react';

import NavTabs from "./features/navigation/NavTabs";
import AppRoute from "./config/route";
import {BrowserRouter} from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {theme} from "./config/theme";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BASE_PATH} from "./utils/routingPaths";
import api from "./config/axiosConfig";

function App() {
  const isDark = useSelector(state => state.app.isDark)
  const token = useSelector(state => state.app.token)

    useEffect(() => {
        if (token !== null) {
            api.defaults.headers['Authorization'] = `Bearer ${token}`
        } else {
            delete api.defaults.headers['Authorization']
        }

    }, [token])

  return (
      <>
        <BrowserRouter basename={BASE_PATH}>
          <ThemeProvider theme={theme(isDark)}>
            <CssBaseline />
            <ToastContainer position={"bottom-left"}/>
            <NavTabs />
            <AppRoute />
          </ThemeProvider>
        </BrowserRouter>
      </>
  );
}

export default App;
