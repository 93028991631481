import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {persistReducer, persistStore} from "redux-persist";

import storage from 'redux-persist/lib/storage'

import appReducer from '../state/appSlice';
import usersReducer from '../pages/user-management/state/usersSlice';


const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['app']
}

const rootReducer = combineReducers({
  app: appReducer,
  users: usersReducer
})

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer)
});

export const persistor = persistStore(store)
