import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import {useDispatch, useSelector} from "react-redux";
import {statuses} from "./state/userStatus";
import FilterButton from "../../features/table/FilterButton";
import {changeFilter, removeFilter, resetFilter} from "./state/usersSlice";

export const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();
    const dispatch = useDispatch()

    const filters = useSelector(state => state.users.filters)

    const headColumns = ["Email", "Imei", "Status"]
    const columns = ["email", "imei", "status"]

    const filterFields = columns.map((id, index) => {
        switch (id) {
            case 'status': return {id: id, label: headColumns[index], value: filters[id], type: "simpleSelect", options: statuses}
            default: return {id: id, label: headColumns[index], value: filters[id], type: "textField"}
        }
    })

    const filterButton = <FilterButton filters={filters}
                                       fields={filterFields}
                                       changeFilter={changeFilter}
                                       resetFilter={resetFilter}
    />

    const handleDelete = (key) => {
        dispatch(removeFilter(key))
    }

    return (
        <>
            <Toolbar className={classes.root}>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Users management
                </Typography>

                {filterButton}
            </Toolbar>

            {filters && Object.keys(filters).map((key, index) => (
                filters[key] && filters[key].length !== 0 &&
                <Chip
                    id={key}
                    label={key + ' = ' + filters[key]}
                    onDelete={() => handleDelete(key)}
                    variant="outlined"
                    className={classes.chip}
                />
            ))}
        </>
    );
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: 'auto',
    },
    button: {
        margin: theme.spacing(0.5)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}));


